<template>
    <div class="main-nav-container unprintable">
        <nav class="main-nav">
            <div class="header-notification">Professional Inspector of Election for your next HOA Election</div>
            <div class="main-nav-content">
                <div :class="{'main-nav-toggle':true,'active':showNav}" @click="showNav = !showNav">
                    <div class="main-nav-toggle-bar"></div>
                    <div class="main-nav-toggle-bar"></div>
                    <div class="main-nav-toggle-bar"></div>
                </div>
                <img src="@/assets/logo.svg" class="main-nav-logo">
                <ul :class="{'main-nav-options-wrapper':true, 'active':showNav}">
                    <li><a class="main-nav-option" href="https://officialhoaelections.com">Home</a></li>
                    <li><a class="main-nav-option" href="https://officialhoaelections.com/contact-us">Contact Us</a></li>
                    <li><a class="main-nav-option" href="https://officialhoaelections.com/about-us">About Us</a></li>
                    <li><a class="main-nav-option" href="https://officialhoaelections.com/mailings">Mailings</a></li>
                    <li><a class="main-nav-option" href="https://officialhoaelections.com/mail-quote">Mail Quote</a></li>
                    <li><a class="main-nav-option" href="https://officialhoaelections.com/resources">Resources</a></li>
                    <li><a class="main-nav-option" href="https://officialhoaelections.com/testimonials">Testimonials</a></li>
                    <li><router-link class="main-nav-option" :to="{name:'Home'}">Election Timeline</router-link></li>
                </ul>
                <!-- <div class="phone-number">(805) 214-8018</div>
                <div class="main-nav-option-separator"></div>
                <div class="main-nav-option profile">
                    <svg viewBox="0 0 24 24" fill="currentColor" data-ux="UtilitiesMenuIcon" data-typography="NavAlpha" class="x-el x-el-svg c2-z c2-2 c2-s c2-t c2-19 c2-1a c2-1b c2-v c2-w c2-x c2-y c2-11 c2-1c c2-3 c2-12 c2-13 c2-14 c2-q c2-15 c2-16 c2-17 c2-18"><path fill-rule="evenodd" d="M19.62 19.29l.026.71h-2.421l-.028-.658c-.119-2.71-2.48-4.833-5.374-4.833-2.894 0-5.254 2.123-5.373 4.833L6.421 20H4l.027-.71c.098-2.56 1.658-4.896 4.04-6.135-1.169-.99-1.848-2.402-1.848-3.9C6.219 6.357 8.733 4 11.823 4c3.09 0 5.605 2.357 5.605 5.255 0 1.497-.68 2.909-1.85 3.9 2.383 1.239 3.944 3.574 4.041 6.135zM11.822 6.273c-1.754 0-3.18 1.338-3.18 2.982 0 1.645 1.426 2.982 3.18 2.982 1.754 0 3.18-1.337 3.18-2.982 0-1.644-1.426-2.982-3.18-2.982z"></path></svg>
                </div> -->
            </div>
        </nav>
    </div>
</template>
<script type="text/javascript">
    export default {
        data(){
            return {
                showNav: false
            }
        }
    }
</script>
<style lang="scss">
    $mobile-breakpoint: 1300px;
    body{
        padding: 0;
        margin: 0;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
    .main-nav-container{
        &::after{
            content: "";
            height: 120px;
            display: block;
            width: 100%;
        }
    }
    .main-nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20;
    }
    .header-notification{
        width: 100%;
        height: 40px;
        background-color: rgb(22, 22, 22);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
    }
    .main-nav-logo{
        height: 65%;
        margin-right: auto;
        position: relative;
        left: -1em;
        @media screen and (min-width: $mobile-breakpoint){
            margin-right: 1em;
            position: static;
        }
    }
    .main-nav-content{
        width: 100%;
        height: 80px;
        background-color: rgb(40, 56, 120);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 15px rgba(20,40,60,0.5);
    }
    .main-nav-toggle{
        --width: 3em;
        font-size: 7px;
        width: var(--width);
        height: calc(var(--width) * 0.9);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        margin-left: 2rem;
        margin-right: auto;
        &.active .main-nav-toggle-bar{
            opacity: 0;
            &:first-child{
                transform: rotate(135deg);
                top: calc(var(--width) / 2.857);
                opacity: 1;
            }
            &:last-child{
                transform: rotate(-135deg);
                top:  calc(var(--width) / -2.857);
                opacity: 1;
            }
        }
        @media screen and (min-width: $mobile-breakpoint){
            display: none;
        }
    }
    .main-nav-toggle-bar{
        background-color: #FFF;
        width: 100%;
        height: calc(var(--width) / 5);
        position: relative;
        transition-property: top, transform, opacity;
        transition-duration:  0.3s;
        transition-timing-function: ease-in-out;
        top: 0;
        opacity: 1;
    }
    .main-nav-options-wrapper{
        position: fixed;
        top: 120px;
        left: -100vw;
        width: 100vw;
        min-height: calc(100vh - 120px);
        background-color: rgba(0,0,0,0.85);
        z-index: 2;
        padding: 0;
        margin: 0;
        &.active{
            left: 0;
            transition: left 0.15s ease-in-out;
        }
        @media screen and (min-width: $mobile-breakpoint){
            display: flex;
            min-height: unset;
            width: unset;
            position: static;
            background: transparent;
            color: #fff;
            list-style-type: none;
        }
    }
    .main-nav-option{
        letter-spacing: 1.5px;
        margin: 1em;
        cursor: pointer;
        color: #FFF;
        text-decoration: none;
        display: inline-block;
        white-space: nowrap;
        &:hover{
            color: #748DFE;
            @media screen and (min-width: $mobile-breakpoint){
                color: rgb(198,198,198);
            }
        }
        @media screen and (min-width: $mobile-breakpoint){
            margin: 0 0.75vw;    
        }
    }
    .main-nav-option-separator{
        width: 0.5px;
        height: 18px;
        background-color: #fff;
        margin: 2%;
    }
  /*  .credits{
        margin-right: 4%;
    }
    .profile{
        margin: 0;
        width: 30px;
        height: auto;
    }*/
    /*.logo{
        position: absolute;
        left: 1%;
        height: 50px;
        display: flex;
        margin-right: 2%;
    }*/
</style>