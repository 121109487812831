<template>
    <div class="home">
        <div class="jumbotron unprintable">
            <div class="jumbotron-content">
                <h1 class="election-title">Election Timeline</h1>
                <div class="election-instructions">
                    Start below by entering your election date
                </div>
                <label for="election-date-picker" class="election-label"
                    >Election Date</label
                >
                <VueCtkDateTimePicker
                    :only-date="true"
                    label="Select your date"
                    id="election-date-picker"
                    v-model="electionDate"
                    :auto-close="true"
                    format="YYYY-MM-DD"
                    formatted="ddd, MMM D, YYYY"
                    class="election-input"
                ></VueCtkDateTimePicker>
                <label for="number-of-days" class="election-label"
                    >Select days to prepare Final Candidate List</label
                >
                <v-select
                    :options="nominationDeadlineToCandidateListOptions"
                    label="Select number of days"
                    id="number-of-days"
                    v-model="nominationDeadlineToCandidateListDays"
                    class="election-input"
                ></v-select>
                <label for="number-of-days" class="election-label"
                    >Organization Name</label
                >
                <input
                    type="text"
                    id="organizationName"
                    v-model="organizationName"
                    class="election-input election-text-input"
                    placeholder="Enter your organization name"
                    @keyup.enter="loadTimeline(true)"
                />
                <div class="timeline-button-container">
                    <!-- <button @click="loadTimeline" :disabled="notValidDateSelected" class="timeline-button">Load Timeline</button> -->
                    <button
                        @click="printTimeline"
                        :disabled="notValidDateSelected"
                        class="timeline-button unprintable"
                        v-if="timelineLoaded"
                        ref="printTimelineButton"
                    >
                        Print Timeline
                    </button>
                </div>
            </div>
        </div>
        <div class="home-content">
            <timeline
                :events="events"
                :organizationName="organizationName"
            ></timeline>
            <button
                @click="printTimeline"
                :disabled="notValidDateSelected"
                class="timeline-button unprintable"
                v-if="timelineLoaded"
            >
                Print Timeline
            </button>
        </div>
    </div>
</template>

<script>
import timeline from "@/components/timeline";
import electionEvents from "@/mixins/election-events.mixin.js";
export default {
    name: "Home",
    mixins: [electionEvents],
    data() {
        return {
            electionDate: "",
            organizationName: "",
            nominationDeadlineToCandidateListOptions: [1, 2, 3, 4, 5],
            nominationDeadlineToCandidateListDays: 5,
            events: [],
            timelineLoaded: false,
            navSize: 120,
        };
    },
    components: {
        timeline,
    },
    computed: {
        notValidDateSelected() {
            if (
                this.electionDate &&
                this.electionDate.length > 0 &&
                this.nominationDeadlineToCandidateListDays
            ) {
                return false;
            } else {
                return "disabled";
            }
        },
    },
    methods: {
        generateElectionTimeline() {
            const events = this.electionEvents(
                this.electionDate,
                this.nominationDeadlineToCandidateListDays,
            );
            events.forEach((event) => {
                event.date = event.date.format("MMM D, YYYY");
            });
            return events;
        },
        scrollToTimeline() {
            let printButtonTopOffset = 0;
            let currentNode = this.$refs.printTimelineButton;
            while (currentNode) {
                printButtonTopOffset += currentNode.offsetTop;
                currentNode = currentNode.offsetParent;
            }
            printButtonTopOffset;
            window.scrollTo({
                top: printButtonTopOffset - this.navSize - 5,
                left: 0,
                behavior: "smooth",
            });
        },
        async loadTimeline(scrollToTimeline = false) {
            if (!this.notValidDateSelected) {
                this.events = this.generateElectionTimeline();
                this.timelineLoaded = true;
                await this.$nextTick();
                if (scrollToTimeline) {
                    this.scrollToTimeline();
                }
            }
        },
        isWebPSupported() {
            const elem = document.createElement("canvas");
            if (elem.getContext && elem.getContext("2d")) {
                // was able or not to get WebP representation
                return (
                    elem.toDataURL("image/webp").indexOf("data:image/webp") == 0
                );
            } else {
                // very old browser like IE 8, canvas not supported
                return false;
            }
        },
        printTimeline() {
            print();
        },
    },
    watch: {
        electionDate() {
            this.loadTimeline(this.organizationName);
        },
        nominationDeadlineToCandidateListDays() {
            this.loadTimeline(this.organizationName);
        },
    },
};
</script>
<style lang="scss">
.no-print {
    @media print {
        display: none;
    }
}
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    --content-width: min(90vw, 1200px);
    min-height: calc(100vh - 120px);
    @media print {
        min-height: unset;
    }
}
.home-content {
    width: var(--content-width);
    padding: 2% 0;
    @media print {
        padding: 0;
        min-width: 672px; /* 7in * 96px/in */
        max-width: 672px; /* 7in * 96px/in */
    }
}
.jumbotron-content {
    background-color: #fff;
    width: var(--content-width);
    padding: 2%;
    box-shadow: 0 0 15px rgba(20, 40, 60, 0.2);
    font-size: 18px;
    position: relative;
    z-index: 2;
}
.jumbotron {
    width: 100%;
    height: 50vh;
    min-height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
    flex-direction: column;
    box-shadow: inset 0 0 15px rgba(20, 40, 60, 0.1);
    &::after {
        content: "";
        background-image: url("~@/assets/jumbotron-image.webp");
        background-image: url("~@/assets/jumbotron-image.jpg");
        background-position: center;
        background-attachment: fixed;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: blur(2px);
    }
}
.election-title {
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
.election-instructions {
    text-align: center;
    margin-top: 0.5em;
}
.election-label {
    display: block;
    margin: 1em 0 0.5em 0;
    font-weight: 600;
}
.election-input {
    margin: 0.5em 0;
}
.timeline-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 992px) {
        flex-direction: row;
    }
}
.datetimepicker:not(.inline) .flex.datepicker[data-v-17c053f2] {
    @media screen and (max-width: 415px) {
        top: 120px !important;
        height: calc(100% - 120px) !important;
    }
}
.timeline-button {
    all: unset;
    margin: 0 auto;
    display: block;
    margin-top: 1.5em;
    padding: 1.5vw 4vw;
    text-align: center;
    background-color: #283878;
    color: #fff;
    -webkit-text-fill-color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
    &:disabled {
        pointer-events: none;
        background-color: #3d3d3d;
    }
    &:hover {
        background-color: #2953cc;
    }
}
.election-text-input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 0.8em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}
</style>
