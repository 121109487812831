import fedHolidays from '@18f/us-federal-holidays';
import moment from "moment-business-days"
export default {
    methods:{
        getNthWeekday(nth, weekday, month, year){
            weekday = weekday.toLowerCase()
            month = month.toLowerCase()
            const monthLookup = ["january","february","march","april","may","june","july","august","september","october","november","december"]
            const monthIndex = monthLookup.indexOf(month)
            const dayLookup = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"]
            const dayIndex = dayLookup.indexOf(weekday)
            const startDate = moment({year, month:monthIndex});
            let weeksToAdvance = nth - 1;
            const firstOccurranceOfDay = startDate.weekday(dayIndex);
            if(firstOccurranceOfDay.month() != monthIndex){
                weeksToAdvance++;
            }
            return firstOccurranceOfDay.add(weeksToAdvance, 'weeks').format("YYYY-M-D");
        },
        californiaHolidays(year){
            const stateHolidays = []
            // Lincoln day
            stateHolidays.push(`${year}-2-12`)
            // Caesar Chavez day
            stateHolidays.push(`${year}-3-31`)
            // Admission day
            stateHolidays.push(`${year}-9-9`)
            // Native American day
            stateHolidays.push(this.getNthWeekday(4, 'friday','september',year))
            return stateHolidays
        },
        electionEvents(electionDate, nominationDeadlineToCandidateListDays){
            // Create lists for pre election events
            const preElectionEvents = [
                {
                    "title":"Election Rules Finalized",
                    "type":"absolute",
                    "description":"(Election rules may not be amended within 90 days of the Election)",
                    "body":{
                        "heading":``,
                        "body":`If the <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200SB323" 
                                        class='election-timeline-content-link' 
                                        target="_blank" 
                                        rel="noopener">election rules</a> are being amended, general notice of 
                            the proposed election rules must have been provided at least 28 DAYS
                            before the board vote to adopt the amended rules. 
                            (<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=4360.&lawCode=CIV" 
                                        class='election-timeline-content-link' 
                                        target="_blank" 
                                        rel="noopener">Civ. Code § 4360</a>.)`,
                        "footer":`*If amended language includes only what is required by law, 
                            the 28 day notice period does not apply. 
                            (<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=4355.&lawCode=CIV" 
                                        class='election-timeline-content-link' 
                                        target="_blank" 
                                        rel="noopener">Civ. Code § 4355(b)</a>.)`
                    },
                    "date":[-90,'day']
                },
                {
                    "title":"Voter List Finalized and Ballot Materials Sent",
                    "type":"absolute",
                    "description":"(At least 30 days before voting deadline)",
                    "body":{
                        "heading":`(The ‘<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=5105.&lawCode=CIV" 
                                        class='election-timeline-content-link' 
                                        target="_blank" 
                                        rel="noopener">Record Date</a>’ for determining the Voter List is established at the time 
                            ballots are mailed or delivered to each member on the Voter List)`,
                        "body":`
                            <ul class="election-timeline-content-list">
                                <li class="election-timeline-list-item">
                                    <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=5115.&lawCode=CIV" 
                                        class='election-timeline-content-link' 
                                        target="_blank" 
                                        rel="noopener">Ballots & Two (2) pre-addressed envelopes</a>
                                </li>
                                <li class="election-timeline-list-item">Instructions on how to return ballots</li>
                                <li class="election-timeline-list-item">
                                    <div>Copy of election rules or this statement on the ballot in 12-point font:</div>
                                    <div>“The rules governing this election may be found here: [website where rules are posted]”</div>
                                </li>
                            </ul>
                        `,
                        "footer":""
                    },
                    "date":[-30,'day']
                },
                {
                    "title":"Final Candidate List and Notice of Meeting",
                    "type":"relative",
                    "description":"(At least 30 days before sending ballot materials)",
                    "body":{
                        "heading":`(<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=4045.&lawCode=CIV" 
                            class='election-timeline-content-link' 
                            target="_blank" 
                            rel="noopener">General Notice</a>)`,
                        "body":`
                            The Notice of Meeting & Candidate List includes all the following information:
                            <ul class="election-timeline-content-list">
                                <li class="election-timeline-list-item">Candidate List</li>
                                <li class="election-timeline-list-item">Notice of date, time, address of where to send ballots</li>
                                <li class="election-timeline-list-item">Notice of date, time, address of ballot counting meeting</li>
                                <li class="election-timeline-list-item">
                                    Statement of member’s right to verify accuracy of individual 
                                    information on both the Candidate List and Voter List
                                </li>
                                <li class="election-timeline-list-item">
                                    Statement of member’s right to request individual delivery of the above items
                                </li>
                            </ul>
                        `,
                        "footer":""
                    },
                    "date":[-30,'day']
                },
                {
                    "title":"Deadline for Nominations",
                    "type":"relative",
                    "date":[-nominationDeadlineToCandidateListDays,'day'],
                    "description":"",
                    "body":{
                        "heading":``,
                        "body":``,
                        "footer":`(30-day nomination period)`
                    },
                },
                {
                    "title":"First Notice (Nomination & Procedure)",
                    "type":"relative",
                    "description":"(At least 30 days before Nomination Deadline)",
                    "body":{
                        "heading":`(<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=4045.&lawCode=CIV" 
                            class='election-timeline-content-link' 
                            target="_blank" 
                            rel="noopener">General Notice</a>)`,
                        "body":`Notice of the procedure and deadline for nomination. 
                            (<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=5115.&lawCode=CIV" 
                                class='election-timeline-content-link' 
                                target="_blank" 
                                rel="noopener">Civ. Code § 5115(a)</a>.)`,
                        "footer":""
                    },
                    "date":[-30,'day']
                },
            ];
            // Create lists for post election events
            const postElectionEvents = [
                {
                    "title":"Notice of Election Results",
                    "description":"(Within 15 days after election)",
                    "body":{
                        "heading":`(<a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=4045.&lawCode=CIV" 
                            class='election-timeline-content-link' 
                            target="_blank" 
                            rel="noopener">General Notice</a>)`,
                        "body":"Notice of the election results must be provided on or before this date.",
                        "footer":""
                    },
                    "type":"absolute",
                    "date":[15,'day']
                }
            ]
            // Create list for all events
            const events = [];
            // Event data for election day
            const currentYear = parseFloat(electionDate.substring(0,4));
            const options = { shiftSaturdayHolidays: true, shiftSundayHolidays: true };
            // federal holidays
            const currentYearFederalHolidays = fedHolidays.allForYear(currentYear, options)
                .map((holiday)=>holiday.dateString);
            const previousYearFederalHolidays = fedHolidays.allForYear(currentYear - 1, options)
                .map((holiday)=>holiday.dateString);
            const nextYearFederalHolidays = fedHolidays.allForYear(currentYear + 1, options)
                .map((holiday)=>holiday.dateString);
            // state holidays
            const currentYearStateHolidays = this.californiaHolidays(currentYear);
            const previousYearStateHolidays = this.californiaHolidays(currentYear - 1);
            const nextYearStateHolidays =  this.californiaHolidays(currentYear + 1);
            // compile holidays
            const currentYearHolidays = [...currentYearFederalHolidays, ...currentYearStateHolidays];
            const previousYearHolidays = [...previousYearFederalHolidays, ...previousYearStateHolidays];
            const nextYearHolidays = [...nextYearFederalHolidays, ...nextYearStateHolidays];

            const holidays  = [...previousYearHolidays, ...currentYearHolidays, ...nextYearHolidays];
            moment.updateLocale('us',{
                holidays,
                holidayFormat:"YYYY-M-D",
                workingWeekdays: [1, 2, 3, 4, 5]
            })
            const momentElectionDate = moment(electionDate, "YYYY-MM-DD");
            const electionDay = {
                "title":"Election Date",
                "description":"",
                "body":{
                    "heading":``,
                    "body":"(Ballot counting meeting)",
                    "footer":``
                },
                "date":momentElectionDate
            };
            events.push(electionDay)
            // Coalesce election event categories
            const eventGroups = [preElectionEvents, postElectionEvents];
            eventGroups.forEach((group)=>{
                let previousEventDate = momentElectionDate;
                group.forEach((event)=>{
                    let eventDate;
                    // If type is relative, calculate each date based on previous date,
                    // Else base off of election date
                    if(event.type=="relative"){
                        eventDate = previousEventDate.clone().add(...event.date);
                    }else{
                        eventDate = momentElectionDate.clone().add(...event.date);
                    }
                    // If event date is a business day, use the nearest previous business day
                    if(!eventDate.isBusinessDay()){
                        eventDate = eventDate.prevBusinessDay();
                    }
                    events.push({
                        title:event.title,
                        description:event.description || "",
                        body:event.body || {"heading":"","body":"","footer":""},
                        date:eventDate
                    });
                    // update previous event Date
                    previousEventDate = eventDate;
                });
            });
            // Sort events by date
            events.sort((a,b)=>{
                return a.date.format("x") - b.date.format("x");
            })
            return events;
        }
    }
}