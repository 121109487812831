import Vue from 'vue'
import VueRouter from 'vue-router';
import routes from './routes'

Vue.use(VueRouter)
const homeURL = "https://timeline.officialhoaelections.com"

const commonMetaTags = [
  {
    property: 'og:site_name',
    content: 'Official HOA Elections'
  },
  {
    property: 'og:tyoe',
    content: 'website'
  },
  // {
  //   property: 'twitter:card',
  //   content: 'summary_large_image'
  // },
  // {
  //   property: 'twitter:site',
  //   content: '@officialhoaelections'
  // },
  // {
  //   property: 'twitter:creator',
  //   content: '@officialhoaelections'
  // },
  {
    property: 'og:locale',
    content: 'en_US'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset:{x:0, y:100}
      }
    }
    return { x: 0, y: 0 }
  }
})


router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle){
    document.title = nearestWithTitle.meta.title;
  }else if(previousNearestWithMeta){
    document.title = previousNearestWithMeta.meta.title
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta){
    return next();
  }
  [...commonMetaTags,...nearestWithMeta.meta.metaTags].map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));
  const canonicalTag = document.createElement("link");
  const pathChars = to.path.split("");
  if(pathChars[pathChars.length - 1] == "/"){
    pathChars.pop();
  }
  const path = pathChars.join("");
  canonicalTag.setAttribute('data-vue-router-controlled', '');
  canonicalTag.href = homeURL + path;
  canonicalTag.rel = "canonical";
  document.head.appendChild(canonicalTag)
  const homeTag = document.createElement("link");
  homeTag.setAttribute('data-vue-router-controlled', '');
  homeTag.href=homeURL;
  homeTag.rel="home";
  document.head.appendChild(homeTag)
  next();
});
export default router
export {commonMetaTags};
