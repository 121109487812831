import Home from '../views/Home.vue'
export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'HOA Director Election Timeline | Official HOA Elections',
      metaTags: [
        {
          property: 'og:title',
          content: 'Timeline'
        },
        {
          name: 'description',
          content: 'Official HOA Elections is the company to use when you need professional,\
          accurate and efficient inspector of elections for your board of directors HOA election.'
        },
        {
          property: 'og:description',
          content: 'Official HOA Elections is the company to use when you need professional,\
          accurate and efficient inspector of elections for your board of directors HOA election.'
        }
      ],
    }
  },
  {
    path: '/404',
    name: '404',
    component: ()=>import(/* webpackChunkName: "group-404" */ '@/views/404.vue'),
    meta: {
      title: 'Page Not Found | Official HOA Elections',
      metaTags: [
        {
          property: 'og:title',
          content: 'Page Not Found'
        }
      ],
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]