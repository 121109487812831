<template>
    <div class="timeline">
        <div class="timeline-watermark print-only" v-if="organizationName">
            <div class="timeline-watermark-text">
                Election timeline for {{ organizationName }}
            </div>
            <img src="@/assets/logo.svg" class="timeline-watermark-image" />
        </div>
        <div class="timeline-watermark print-only" v-else>
            <div class="timeline-watermark-text">
                Election timeline provided by
            </div>
            <img src="@/assets/logo.svg" class="timeline-watermark-image" />
        </div>
        <div
            v-for="(event, i) in events"
            :key="i"
            class="timeline-event-container"
        >
            <div class="timeline-event">
                <div class="timeline-title-card">
                    <div
                        class="timeline-event-date"
                        :style="{ '--content': `' - ${event.title}'` }"
                    >
                        <calendar-button
                            class="no-print"
                            :title="event.title"
                            :date="event.date"
                            :organizationName="organizationName"
                        ></calendar-button>
                        <span>
                            {{ event.date }}
                        </span>
                    </div>
                    <div class="timeline-event-description">
                        {{ event.description }}
                    </div>
                </div>
                <div class="timeline-description-card-container">
                    <div class="timeline-description-card">
                        <div class="timeline-event-title">
                            {{ event.title }}
                        </div>
                        <div class="timeline-event-body">
                            <div
                                v-html="event.body.heading"
                                class="timeline-event-body-heading"
                            ></div>
                            <div v-html="event.body.body"></div>
                            <div
                                v-html="event.body.footer"
                                class="timeline-event-body-footer"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import calendarButton from "@/components/calendar-button";
export default {
    props: {
        events: {
            type: Array,
            default: () => [],
        },
        organizationName: {
            type: String,
        },
    },
    components: {
        calendarButton,
    },
};
</script>
<style lang="scss">
.timeline {
    position: relative;
    @media print {
        font-size: 12px;
        display: flex;
        flex-direction: column;
    }
    &::after {
        --timeline-bar-width: 0.25em;
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: var(--timeline-bar-width);
        background-color: #283878;
        border-radius: 9999em;
        left: calc(-1 * var(--timeline-bar-width) / 2);
        @media screen and (min-width: 992px) {
            left: calc(50% - var(--timeline-bar-width) / 2);
        }
        @media print {
            display: none;
        }
    }
}
.timeline-watermark {
    justify-content: center;
    width: 75%;
    align-items: center;
    margin: 0 auto;
}
.timeline-watermark-text {
    color: #3e3c6f;
    font-size: 1.75em;
    font-weight: 600;
    white-space: nowrap;
}
.timeline-watermark-image {
    width: 20%;
}
.timeline-event-container {
    /*margin: 2em 0;*/
    @media print {
        margin: 0;
    }
}
.timeline-event {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (min-width: 992px) {
        flex-direction: row;
    }
}
.timeline-title-card {
    height: 20%;
    padding: 1% 5%;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    @media screen and (min-width: 992px) {
        width: 50%;
        text-align: right;
        padding: 2% 5%;
    }
    @media print {
        margin: 0;
        padding: 0;
        height: fit-content;
    }
}
.timeline-description-card-container {
    height: 20%;
    text-align: left;
    padding: 1% 5%;
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: 992px) {
        width: 50%;
        padding: 2% 5%;
    }
    @media print {
        padding: 0;
        height: fit-content;
    }
}
.timeline-event-date {
    font-size: 1.75em;
    font-weight: 600;
    color: #283878;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    @media screen and (min-width: 992px) {
        flex-direction: row;
    }
    @media print {
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 0.4rem;
        &::after {
            margin-left: 0.25em;
            content: var(--content);
        }
    }
}
.timeline-event-description {
    font-size: 1em;
    margin-top: 0.5em;
    line-height: 1.5em;
    @media print {
        margin: 0;
        line-height: 1.4rem;
    }
}
.timeline-description-card {
    box-shadow: 0 0 15px rgba(20, 40, 60, 0.1);
    /*border-radius: 0.375em;*/
    @media print {
        box-shadow: none;
    }
}
.timeline-event-title {
    position: relative;
    background-color: #2953cc;
    color: #fff;
    font-size: 1.25em;
    padding: 5%;
    /*        border-radius: 0.375rem 0.375rem 0 0*/
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &::before {
        --diameter: 0.15em;
        --offset: calc(var(--content-width) * -0.05 - var(--diameter) / 2);
        content: "";
        position: absolute;
        top: calc(50% - var(--diameter) / 2);
        left: var(--offset);
        width: calc(var(--offset) * -1);
        height: var(--diameter);
        background-color: #283878;
        @media print {
            display: none;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: -1em;
        border-width: 0.5em;
        border-color: transparent;
        border-right-color: #2953cc;
        border-style: solid;
        top: calc(50% - 0.5em);
        @media print {
            display: none;
        }
    }
    @media print {
        display: none;
    }
}
.election-timeline-content-link {
    color: #2953cc;
    text-decoration: none;
    @media print {
        color: #222;
    }
}
.election-timeline-content-list {
    margin: 0;
}
.timeline-event-body {
    padding: 5%;
    line-height: 2em;
    font-size: 1.15em;
    border-radius: 0 0 0.375rem 0.375rem;
    @media print {
        padding: 0;
        line-height: 1.2rem;
    }
}
.timeline-event-body-heading,
.timeline-event-body-footer {
    font-style: italic;
}
</style>
