<template>
    <span class="calendar-button">
        <img
            src="@/assets/calendar-add.svg"
            alt="open add to calendar menu"
            class="calendar-button-toggle-icon"
        />
        <div class="calendar-add-row">
            <a
                :href="googleCalendarLink"
                rel="noopener"
                target="_blank"
                class="calendar-add-row-link"
            >
                <img
                    src="@/assets/google-calendar.svg"
                    alt="add to google calendar"
                    class="calendar-add-row-icon"
            /></a>
            <a
                :href="outlookCalendarLink"
                rel="noopener"
                target="_blank"
                class="calendar-add-row-link"
            >
                <img
                    src="@/assets/outlook-calendar.svg"
                    alt="add to outlook calendar"
                    class="calendar-add-row-icon"
                />
            </a>
        </div>
    </span>
</template>
<script type="text/javascript">
import moment from "moment-business-days";
import { google, outlook } from "calendar-link";
export default {
    props: {
        date: {
            type: String,
        },
        title: {
            type: String,
        },
        organizationName: {
            type: String,
        },
    },
    data() {
        return {
            formattedDate: "",
            formattedTime: "08:00:00",
            googleCalendarLink: "#",
            outlookCalendarLink: "#",
        };
    },
    methods: {
        updateEventDetails() {
            let title;
            if (this.organizationName) {
                title = `${this.organizationName} - ${this.title}`;
            } else {
                title = this.title;
            }
            title = title.replace("&", "and");
            const event = {
                title: title,
                start: `${this.formattedDate} ${this.formattedTime}`,
                duration: [1, "hour"],
            };
            this.googleCalendarLink = google(event);
            this.outlookCalendarLink = outlook(event);
        },
    },
    watch: {
        date: {
            immediate: true,
            handler(date) {
                const momentDate = moment(date, "MMM D, YYYY");
                this.formattedDate = momentDate.format("YYYY-MM-DD");
                this.updateEventDetails();
            },
        },
        title: {
            immediate: true,
            handler() {
                this.updateEventDetails();
            },
        },
        organizationName: {
            immediate: true,
            handler() {
                this.updateEventDetails();
            },
        },
    },
};
</script>
<style lang="scss">
.calendar-button {
    display: flex;
    align-items: center;
    border-radius: 999em;
    background-color: transparent;
    transition: background-color 0.075s ease-in-out;
    height: 1.5em;
    width: 1.5em;
    margin: 0 0.5em;
    justify-content: center;
    position: relative;
    &:hover {
        background-color: rgba(20, 40, 60, 0.1);
        .calendar-add-row {
            max-height: 2em;
        }
    }
}
.calendar-button-toggle-icon {
    width: 0.9em;
    cursor: pointer;
}
.calendar-add-row {
    position: absolute;
    top: 1.75em;
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 15px rgba(20, 40, 60, 0.1);
    max-height: 0;
    overflow: hidden;
    transition: max-height, padding, 0.15s ease-in-out;
    display: flex;
    z-index: 2;
}
.calendar-add-row-link {
    padding: 0;
    margin: 0;
}
.calendar-add-row-icon {
    height: 1em;
    padding: 0.5em;
    transition: background-color 0.075s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: rgba(20, 40, 60, 0.1);
    }
}
</style>
