<template>
  <div id="app">
    <main-nav></main-nav>
    <router-view />
    <main-footer></main-footer>
  </div>
</template>

<script type="text/javascript">
import mainNav from "@/components/main-nav";
import mainFooter from "@/components/main-footer";
export default {
  components: {
    mainNav,
    mainFooter,
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.unprintable {
  @media print {
    display: none !important;
  }
}
.print-only {
  display: none;
  @media print {
    display: flex;
  }
}
</style>
